import { render, staticRenderFns } from "./GameVendors.vue?vue&type=template&id=2c071f07&scoped=true&lang=pug&"
import script from "./GameVendors.vue?vue&type=script&lang=js&"
export * from "./GameVendors.vue?vue&type=script&lang=js&"
import style0 from "./GameVendors.vue?vue&type=style&index=0&id=2c071f07&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c071f07",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBreadcrumbs,QIcon,QBreadcrumbsEl,QCard});
