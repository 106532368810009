//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/icons/game-vendors';
import { preFetchPage } from '@/helpers/router';
import Btn from '@/components/Controls/Btn';
import SectionHeader from '@/components/Controls/SectionHeader';
import GameSlider from '@/components/Games/GameSlider';
import Games from '@/components/Games/Games';
export default {
  name: 'GameProvider',
  preFetch: preFetchPage,
  components: {
    'app-btn': Btn,
    'app-section-header': SectionHeader,
    'app-game-slider': GameSlider,
    'app-games': Games
  },
  props: {
    config: Object
  },
  meta: function meta() {
    try {
      return {
        title: this.seoPage.data.seo.title,
        meta: {
          description: {
            name: 'description',
            content: this.seoPage.data.seo.description
          },
          keywords: {
            name: 'keywords',
            content: this.seoPage.data.seo.keywords
          }
        }
      };
    } catch (e) {
      this.$store.log(e);
      return {};
    }
  },
  computed: {
    seoPage: function seoPage() {
      return this.$store.getters['statics/seoPage'];
    },
    lang: function lang() {
      return this.$store.getters['lang/lang'];
    },
    defaultLang: function defaultLang() {
      return this.$store.getters['lang/defaultLang'];
    },
    sections: function sections() {
      return this.$store.getters['sidebar/pageSections'];
    }
  },
  methods: {
    linkToMainPage: function linkToMainPage() {
      return this.lang === this.defaultLang ? '/' : "/".concat(this.lang);
    },
    linkTo: function linkTo(_ref) {
      var page = _ref.page,
          url = _ref.url;
      return this.$store.getters['lang/linkTo']({
        page: page,
        params: url
      });
    }
  }
};